<template>
  <div class="remoteSd" v-loading="loading">
    <div class="tip" v-if="status !== 1">{{ tips[status] }}</div>
    <div class="screenBox" ref="screenBox" v-show="status === 1"></div>
  </div>
</template>

<script>
import RFB from '@novnc/novnc/lib/rfb'
import { decrypt } from '@/lib/crypto.js'
export default {
  data() {
    return {
      tips: [
        this.$t('connecting'),
        this.$t('connectionSuccess'),
        this.$t('connectionFail'),
        this.$t('connectionClean')
      ],
      status: 0, //状态和tips数组下标对应
      rfb: null,
      loading: false
    }
  },
  async mounted() {
    window.addEventListener('beforeunload', this.clear)
    this.$nextTick(() => {
      let { isTest } = this.$route.query
      if (isTest) {
        //测试
        this.connectVncTest()
      } else {
        //动态
        this.connectVnc()
      }
    })
  },

  //销毁 断开连接
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.clear)
    localStorage.removeItem(`remote${this.$route.query.sn}`)
    this.clear()
  },

  methods: {
    clear() {
      this.rfb?._disconnect()
    },
    //连接
    connectVnc() {
      try {
        const { sn } = this.$route.query
        if (!sn) {
          throw new Error('sn empty')
        }
        const d = localStorage.getItem(`remote${sn}`)
        if (!d) {
          throw new Error(`remote${sn} empty`)
        }
        let data = decrypt(d)
        data = JSON.parse(data)
        const { innerIp, ip, port, password } = data
        this.status = 0
        const ws = location.href.includes('https') ? 'wss://' : 'ws://'
        const url = `${ws}${ip || innerIp}:${port}/websockify`
        console.log(123, d, data, url)
        this.rfb = new RFB(this.$refs.screenBox, url, {
          credentials: { password }
        })
        this.rfb.addEventListener('connect', this.connectedToServer)
        this.rfb.addEventListener('disconnect', this.disconnectedFromServer)
        this.rfb.scaleViewport = true //scaleViewport指示是否应在本地扩展远程会话以使其适合其容器。禁用时，如果远程会话小于其容器，则它将居中，或者根据clipViewport它是否更大来处理。默认情况下禁用。
        this.rfb.resizeSession = false //是一个boolean指示是否每当容器改变尺寸应被发送到调整远程会话的请求。默认情况下禁用
      } catch (error) {
        this.status = 4
        this.tips[4] = error
        console.log(error)
      }
    },
    //连接测试
    connectVncTest() {
      try {
        this.status = 0
        let { ws, url, password } = this.$route.query
        ws = ws || (location.href.includes('https') ? 'wss://' : 'ws://')
        password = password || '123'
        url = url ? `${ws}${url}/websockify` : `wss://remote.sedsy.com:18858/websockify`
        console.log('---test---', url, password)
        this.rfb = new RFB(this.$refs.screenBox, url, {
          credentials: { password }
        })
        this.rfb.addEventListener('connect', this.connectedToServer)
        this.rfb.addEventListener('disconnect', this.disconnectedFromServer)
        this.rfb.scaleViewport = true //scaleViewport指示是否应在本地扩展远程会话以使其适合其容器。禁用时，如果远程会话小于其容器，则它将居中，或者根据clipViewport它是否更大来处理。默认情况下禁用。
        this.rfb.resizeSession = false //是一个boolean指示是否每当容器改变尺寸应被发送到调整远程会话的请求。默认情况下禁用
      } catch (error) {
        this.status = 4
        this.tips[4] = error
        console.log(error)
      }
    },
    connectedToServer(e) {
      this.status = 1
      console.log('success', e)
    },

    disconnectedFromServer(e) {
      if (e.detail.clean) {
        this.status = 3
        console.log('clean', e.detail.clean)
      } else {
        this.status = 2
        this.clear()
        console.log('connection fail')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.screenBox {
  width: 100%;
  height: calc(100vh - 40px);
  ::v-deep canvas {
    cursor: default !important;
  }
}
.tip {
  text-align: center;
  padding-top: 50px;
  color: #666;
}
</style>
